<template>
  <v-content>
    <v-layout xs12 px-12 pt-8 pb-4 justify-space-between>
      <v-flex d-flex ml-12 justify-start align-center shrink style="text-align: left; cursor: pointer"
        @click="$router.push('/').catch((err) => { })">
        <v-layout>
          <v-flex>
            <span style="color: #3e7662; font-size: 16px">สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย
              กรมแพทย์ทหารบก</span><br />
            <span style="color: #3e7662; font-size: 22px; line-height: 30px"><b>Institutional Review
                Board</b></span><br />
            <span style="color: #3e7662; font-size: 16px">Royal Thai Army Medical Department</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex d-flex justify-end align-end mr-12>
        <div @click="$router.push('/')" style="
            color: #3e7662;
            font-weight: bold;
            cursor: pointer;
            font-size: 22px;
          ">
          <v-icon color="#3E7662">arrow_back</v-icon>
          <span style="text-decoration: underline">หน้าแรก</span>
        </div>
      </v-flex>
    </v-layout>

    <v-layout justify-center>
      <v-flex style="background-color: #efeae7; padding: 2% 10% 2% 10%">
        <v-card class="pa-12">
          <v-layout justify-center>
            <v-flex xs12-pa-4 sm10 md8 lg5 style="text-align: center">
              <v-layout>
                <v-flex>
                  <img src="../assets/logo.png" alt="logo login" style="width: 100px" />
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex pt-6 pb-8>
                  <span style="font-weight: bold; font-size: 24px; color: #3e7662">เข้าใช้งานระบบ</span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex class="text-input font-weight-bold">
                  <v-text-field id="email" :rules="[rules.required, rules.email]" v-model="loginForm.email" hide-details
                    item-color="#3E7662" color="#3E7662" label="อีเมล"></v-text-field>

                  <v-text-field id="password" :rules="[rules.required]" v-model="loginForm.password" hide-details
                    item-color="#3E7662" color="#3E7662" label="รหัสผ่าน" type="password"
                    @keyup.enter="login"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout v-if="onError">
                <v-flex xs10 pt-1 style="text-align: left; font-size: 12px" class="text-input">
                  อีเมล หรือ รหัสผ่านไม่ถูกต้อง
                </v-flex>
                <v-flex xs2 style="text-align: right; color: #3e7662" class="icon-login">
                  <v-icon style="font-size: 20px">warning</v-icon>
                </v-flex>
              </v-layout>
              <v-layout justify-center>
                <v-flex pb-10 shrink>
                  <v-checkbox hide-details color="#3E7662" v-model="checkbox"
                    label="จดจำรหัสผ่าน เข้าสู่ระบบ?"></v-checkbox>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex xs6 pr-1 style="text-align: left">
                  <v-btn color="#3E7662" dark @click="login">
                    <span class="px-4">เข้าสู่ระบบ</span>
                  </v-btn>
                </v-flex>
                <v-flex xs6 pl-1 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="$router.push('/register')">
                    <span class="px-4">ลงทะเบียน</span>
                  </v-btn>
                </v-flex>
              </v-layout>

              <v-layout justify-center>
                <v-flex pb-2 pt-8>
                  <span @click="forgetPassword" style="
                      font-weight: bold;
                      color: #3e7662;
                      text-decoration: underline;
                      cursor: pointer;
                    ">
                    ลืมรหัสผ่าน?
                  </span>
                </v-flex>
              </v-layout>
              <!--                            <v-layout justify-center>-->
              <!--                                <v-flex>-->
              <!--                                    <span @click="forgetName" style="font-weight: bold; color: #3E7662;cursor: pointer;">-->
              <!--                                        ลืมอีเมล?-->
              <!--                                    </span>-->
              <!--                                </v-flex>-->
              <!--                            </v-layout>-->
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <loading color="#527281" :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
    <Footer></Footer>
  </v-content>
</template>

<script>
import Footer from "../components/Footer";
import Swal from "sweetalert2";
import { mapState } from "vuex";
// import MultiselectForm from "../components/input/MultiselectForm";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Login",
  components: {
    Footer,
    // MultiselectForm,
    Loading,
  },
  data: () => ({
    isLoading: false,
    fullPage: true,
    items: [{ name: "ผู้วิจัย/ผู้ร่วมวิจัย" }],
    checkbox: false,
    onError: false,
    loginForm: {
      role: "",
      email: "",
      password: "",
    },

    //Validate
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => value.length <= 20 || "Max 20 characters",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
  }),
  created() {
    this.forceLogin()
  },
  methods: {
    updateData(name, val) {
      this.loginForm[name] = val;
    },
    login() {
      if (this.loginForm.email && this.loginForm.password) {
        this.isLoading = true;
        this.$store
          .dispatch("Auth/login", this.loginForm)
          .then((res) => {
            this.$router.replace("/");
            this.isLoading = false;
          })
          .catch((e) => {
            this.onError = true;
            if (e.response.status === 401) {
              Swal.fire({
                title: "your email are not verified!",
                text: "บัญชียังไม่ได้ยืนยัน กรุณาตรวจสอบทาง email",
                icon: "warning",
                showConfirmButton: false,
                confirmButtonColor: "#3E7662",
              });
              this.isLoading = false;
            } else if (e.response.status === 400) {
              Swal.fire({
                title: "อีเมล หรือ รหัสผ่านไม่ถูกต้อง",
                icon: "warning",
                confirmButtonColor: "#3E7662",
              });
              this.isLoading = false;
            } else {
              Swal.fire({
                title: "ประเภทผู้ใช้งานไม่ถูกต้อง",
                icon: "warning",
                confirmButtonColor: "#3E7662",
              });
              this.isLoading = false;
            }
          });
      } else {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ถูกต้อง!",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      }
    },
    forceLogin() {
      if (this.$route.query.isAdmin == '1') {
        if (this.$route.query.email && this.$route.query.token) {
          console.log('email', this.$route.query.email)
          this.$store
            .dispatch("Auth/forceLogin", { email: this.$route.query.email, token: this.$route.query.token })
            .then((res) => {
              this.$router.replace("/");
              this.isLoading = false;
            }).catch ((e) => {
              this.onError = true;
              if (e.response.status === 401) {
                Swal.fire({
                  title: "your email are not verified!",
                  text: "บัญชียังไม่ได้ยืนยัน กรุณาตรวจสอบทาง email",
                  icon: "warning",
                  showConfirmButton: false,
                  confirmButtonColor: "#3E7662",
                });
                this.isLoading = false;
              } else if (e.response.status === 400) {
                Swal.fire({
                  title: "อีเมล หรือ รหัสผ่านไม่ถูกต้อง",
                  icon: "warning",
                  confirmButtonColor: "#3E7662",
                });
                this.isLoading = false;
              } else {
                Swal.fire({
                  title: "ประเภทผู้ใช้งานไม่ถูกต้อง",
                  icon: "warning",
                  confirmButtonColor: "#3E7662",
                });
                this.isLoading = false;
              }
            });
        }
      }
    },
    forgetPassword() {
      this.$router.push("/forget-password");
    },
    forgetName() {
      alert("Comming soon !");
    },
  },
};
</script>

<style lang="scss">
/*.icon-login {*/
/*    i.v-icon.notranslate.material-icons.theme--light {*/
/*        color: #3E7662;*/
/*    }*/
/*}*/
</style>
